import React from 'react'
import { graphql } from 'gatsby'
import { LinkType } from '../components/shared'

export interface homeProps {
  data: {
    contentfulHome: {
      seoTitle: string
      seoDescription: string
    }
  }
}

const Home = ({
  data: {
    allContentfulProduct: { edges },
  },
}) => {
  return (
    <>
      <br />
      <br />

      {edges.map(({ node: { title, slug } }, i) => (
        <React.Fragment key={i}>
          <LinkType to={slug}>
            {i}. {title}
          </LinkType>
          <br />
        </React.Fragment>
      ))}

      <br />
      <br />
    </>
  )
}

export const ProductQuery = graphql`
  query allproducts {
    allContentfulProduct {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default Home
